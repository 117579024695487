import Spinner from '@/components/Spinner';
import { useUserRoutes } from '@/hooks';
import { useEffect, useState } from 'react';

export const RouteWrapper = ({ children }: { children: React.ReactNode }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { getRoutes } = useUserRoutes();

  useEffect(() => {
    getRoutes().then(() => setIsSuccess(true));
  }, []);

  if (!isSuccess) {
    return <>...</>;
  }
  return <div>{children}</div>;
};
