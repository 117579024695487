import { Button } from '@mui/material';
import { useState, useEffect } from 'react';

export function FloatingActionButton() {
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      setShowButton(isAtBottom);
      console.log(showButton);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {showButton && (
        <Button
          style={{
            position: 'absolute',
            right: '-15px',
            bottom: '40px',
          }}
          variant="contained"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
              fill="white"
            />
            <path
              d="M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V9.5C11.25 9.09 11.59 8.75 12 8.75C12.41 8.75 12.75 9.09 12.75 9.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z"
              fill="white"
            />
            <path
              d="M14.9995 12.2499C14.8095 12.2499 14.6195 12.1799 14.4695 12.0299L11.9995 9.55994L9.52945 12.0299C9.23945 12.3199 8.75945 12.3199 8.46945 12.0299C8.17945 11.7399 8.17945 11.2599 8.46945 10.9699L11.4695 7.96994C11.7595 7.67994 12.2395 7.67994 12.5295 7.96994L15.5295 10.9699C15.8195 11.2599 15.8195 11.7399 15.5295 12.0299C15.3795 12.1799 15.1895 12.2499 14.9995 12.2499Z"
              fill="white"
            />
          </svg>
        </Button>
      )}
    </>
  );
}
