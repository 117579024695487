import { Card as CustomCard } from '@/components/@core/card';
import { TYPOGRAPHY } from '@/pages/ListContentPage/constants';
import { subTitleStyles } from '@/pages/PointsRedemption/components/product-details/product-card';
import { useProductDetail } from '@/pages/PointsRedemption/hooks/useProductDetail';
import { Box, Button, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { AppLayout } from '@/components';

// Obtén la fecha de hoy en el formato deseado
const today = format(new Date(), "d 'de' MMMM 'de' yyyy", { locale: es });

export const SuccessRedemption = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const product = useProductDetail();
  const handleClick = () => {
    navigate('/points', { replace: true });
  };
  return (
    <AppLayout>
      <div>
        <Box
          display={'flex'}
          justifyContent={'end'}
          marginBottom={'1rem'}
          marginTop={'.5rem'}
        >
          <span
            style={{
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.00098 5L19 18.9991"
                stroke="#1F1333"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.99996 18.9991L18.999 5"
                stroke="#1F1333"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Box>
        <CustomCard>
          <Box display={'flex'} justifyContent={'center'}>
            <svg
              width="52"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26 4.5C14.2912 4.5 4.75 14.0412 4.75 25.75C4.75 37.4588 14.2912 47 26 47C37.7088 47 47.25 37.4588 47.25 25.75C47.25 14.0412 37.7088 4.5 26 4.5ZM36.1575 20.8625L24.1087 32.9112C23.8112 33.2087 23.4075 33.3787 22.9825 33.3787C22.5575 33.3787 22.1537 33.2087 21.8563 32.9112L15.8425 26.8975C15.2262 26.2812 15.2262 25.2612 15.8425 24.645C16.4587 24.0287 17.4787 24.0287 18.095 24.645L22.9825 29.5325L33.905 18.61C34.5212 17.9938 35.5413 17.9938 36.1575 18.61C36.7738 19.2263 36.7738 20.225 36.1575 20.8625Z"
                fill="#00E090"
              />
            </svg>
          </Box>

          <Box paddingX={2} paddingY={'1rem'}>
            <Box
              sx={{
                fontSize: '16px',
                textAlign: 'center',
                marginTop: '.5rem',
                fontFamily: TYPOGRAPHY.bold,
              }}
            >
              {t('products.success.title')}
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                textAlign: 'center',
                marginTop: '.5rem',
              }}
            >
              {t('products.success.description')}
            </Box>
          </Box>
        </CustomCard>

        <Box marginTop={'1rem'}>
          <Box sx={subTitleStyles} marginBottom={'1rem'}>
            {t('products.resume')}
          </Box>
          <Box>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              paddingX={'1rem'}
            >
              <strong>{t('products.pointsRedeemed')} :</strong>
              {product.data?.main_price.hacku_points_discount
                ? product.data?.main_price.hacku_points_discount
                : product.data?.main_price.hacku_points}
            </Box>

            <Divider sx={{ marginY: '1rem' }} />
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              paddingX={'1rem'}
            >
              <strong>{t('products.date')}:</strong>
              {today}
            </Box>

            <Divider sx={{ marginY: '1rem' }} />
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              paddingX={'1rem'}
            >
              <strong>{t('products.item')}:</strong>
              {product.data?.name}
            </Box>

            <Divider sx={{ marginY: '1rem' }} />
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              paddingX={'1rem'}
            >
              <strong>{t('products.status')}:</strong>
              {t('products.approved')}
            </Box>

            <Divider sx={{ marginY: '1rem' }} />
          </Box>
        </Box>

        <Box marginTop={'2rem'} display={'flex'} justifyContent={'center'}>
          <Button
            onClick={handleClick}
            variant="contained"
            sx={{
              width: '300px',
              borderRadius: '20px',
              textTransform: 'none',
            }}
          >
            {t('general.back')}
          </Button>
        </Box>
      </div>
    </AppLayout>
  );
};
