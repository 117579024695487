import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';

const IS_SENTRY_ENABLE =
  import.meta.env.VITE_APP_SENTRY_ENABLED &&
  (import.meta.env.VITE_APP_ENVIRONMENT === 'prod' ||
    import.meta.env.VITE_APP_ENVIRONMENT === 'development');

const IS_DATADOG_ENABLE =
  import.meta.env.VITE_APP_ENVIRONMENT === 'prod' ||
  import.meta.env.VITE_APP_ENVIRONMENT === 'production'

const DOG_API_ID = import.meta.env.VITE_APP_DATA_DOG_API_ID || '';
const DOG_CLIENT_TOKEN = import.meta.env.VITE_APP_DATA_DOG_CLIENT_TOKEN || '';
const ENV = import.meta.env.VITE_APP_ENVIRONMENT;
const SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN;

IS_DATADOG_ENABLE &&
  (datadogRum as any).init({
    applicationId: DOG_API_ID,
    clientToken: DOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    service: 'hacku-prod',
    env: ENV,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    defaultPrivacyLevel: 'mask-user-input',
  });

IS_SENTRY_ENABLE &&
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    enabled: IS_SENTRY_ENABLE,
  });
