import { useState, useCallback, useEffect } from 'react';
import { get } from '../services/api';
import { OptionsType } from '../pages/ListContentPage/components/CustomSelect';
import { useTranslation } from 'react-i18next';
import { create } from 'zustand';
import { QueryParamsStrings } from '../constants/queryParamsStrings';
import { useSearchParams } from 'react-router-dom';

// Generated by https://quicktype.io

export interface IRoutesResponse {
  count: number;
  next: null | string;
  previous: null | string;
  results: IRoute[];
}

export interface IRoute {
  route: Route;
  progress_percentage: number;
  is_active: boolean;
}

export interface Route {
  pk: number;
  name: string;
  slug_name: string;
  finished: boolean;
}

export type Metadata = {
  count: number;
  next: null | string;
  previous: null | string;
};

type RoutesStore = {
  routes: IRoute[];
  isLoading: boolean;
  currentRouteId: number;
  setCurrentRouteId: (value: number) => void;
  setRoutes: (value: IRoute[]) => void;
  setIsLoading: (value: boolean) => void;
};

export const routesStore = create<RoutesStore>()((set) => ({
  isLoading: true,
  routes: [],
  currentRouteId: 0,
  setRoutes: (values) => set({ routes: values }),
  setIsLoading: (value) => set({ isLoading: value }),
  setCurrentRouteId: (value) => set({ currentRouteId: value }),
}));

export const useUserRoutes = () => {
  const [searchParamsFromReactRouter] = useSearchParams();
  const {
    routes,
    setRoutes,
    isLoading,
    setIsLoading,
    setCurrentRouteId,
    currentRouteId,
  } = routesStore();
  const [metadata, setMetadata] = useState<Metadata>();
  const { t } = useTranslation();

  const getRoutes = useCallback(async () => {
    const pensum_id = searchParamsFromReactRouter.get(
      QueryParamsStrings.PENSUM_ID,
    );
    try {
      const response = await get(
        `users/:user_pk/routes/?${
          pensum_id ? `route__pensum_route__pensum=${pensum_id}` : ''
        }`,
      );
      const data: IRoutesResponse = response.data;

      if (data && data?.results) {
        setMetadata({
          count: data.count,
          next: data.next,
          previous: data.previous,
        });

        // TODO: this logic is used in other hooks extract  to generic function
        if (data?.next) {
          const nextUrl = data.next;
          const searchParams = new URLSearchParams(nextUrl?.split('?')[1]);
          const offset = searchParams.get('offset');
          const secondPage = await get(
            `users/:user_pk/routes/?offset=${offset}`,
          );
          const results: IRoutesResponse = secondPage.data;
          if (data?.results?.length >= 0 && results?.results?.length >= 0) {
            const newData = [...data?.results, ...results?.results];
            setRoutes(newData);
            const id = getCurrentRoute(data?.results).id;
            setCurrentRouteId(id);
          }
          return;
        }
        setRoutes(data?.results);
        const id = getCurrentRoute(data?.results).id;
        setCurrentRouteId(id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const routeFormater = (data: IRoute[]): OptionsType[] => {
    const currentRoute: IRoute = data.find(
      (item) => item.is_active === true,
    ) || {
      is_active: false,
      progress_percentage: 0,
      route: {
        name: t('contentScreen.selectACourse'),
        pk: 0,
        slug_name: '',
        finished: false,
      },
    };
    const dataWithoutCurrentRoute = data.filter(
      (item) => item.is_active !== true,
    );

    const orderingData = [currentRoute, ...dataWithoutCurrentRoute];

    const fistLetterUpperCase = (word: string) => {
      return word.replace(/\b\w/g, (letter: string) => letter.toUpperCase());
    };

    return orderingData.map(({ route, is_active }) => {
      return {
        label: is_active
          ? `${route.name}  ${
              routes.length > 1
                ? `(${fistLetterUpperCase(t('general.current'))})`
                : ''
            }`
          : route.name,
        id: route.pk,
      };
    });
  };

  const getCurrentRoute = (
    routes: IRoute[],
  ): {
    name: string;
    id: number;
    finished: boolean;
  } => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const selectedRoute = params.get(QueryParamsStrings.SELECTED_ROUTE);
    const route = routes.find((item) => item.is_active === true);
    if (Boolean(selectedRoute)) {
      const route = routes.find(
        (item) => item.route.pk === Number(selectedRoute),
      );
      if (route) {
        return {
          name: route?.route.name ?? '',
          id: route?.route.pk ?? 0,
          finished: route?.route.finished ?? false,
        };
      }
    }
    if (route) {
      return {
        name: route?.route.name ?? '',
        id: route?.route.pk ?? 0,
        finished: route?.route.finished ?? false,
      };
    }
    const defaultRoute = routes[routes.length - 1];
    return {
      name: defaultRoute?.route?.name ?? '',
      id: defaultRoute?.route?.pk ?? 0,
      finished: defaultRoute?.route?.finished ?? false,
    };
  };

  const getOneRouteInfo = (id: number) => {
    const route = routes.find((item) => item.route.pk === id);

    return {
      name: route?.route.name,
      id: route?.route.pk,
      progressPercentage: route?.progress_percentage,
      finished: route?.route.finished,
    };
  };

  return {
    // Properties
    metadata,
    routes,
    isLoading,
    currentRouteId,

    // Methods
    getRoutes,
    routeFormater,
    getOneRouteInfo,
    getCurrentRoute,
  };
};
