import { lazy, useEffect, useRef, useState } from 'react';

// mui components
import {
  Alert,
  Box,
  Typography,
  // useTheme
} from '@mui/material';

import { CustomSelect, Heading, ModuleList } from './components';
import ConfettiExplosion from 'react-confetti-explosion';

const AnnounceCertificate = lazy(() =>
  lazyRetry(() => import('./components/AnnounceCertificate')),
);

// hooks and utils
import { useUserRoutes } from '../../hooks';
import { useMetrics } from '../../hooks/useMetrics';
import { useConfig } from '../../hooks/useConfig';

// icons
import { useModules } from '../../hooks/useModules';
import { useTranslation } from 'react-i18next';
import { ContentProgressModal } from './components/ContentProgressModal';
import { useSearchParams } from 'react-router-dom';
import { UnderConstruction } from './components/UnderConstruction';
import { QueryParamsStrings } from '../../constants/queryParamsStrings';
import { capitalize, lazyRetry } from '../../utils/general';
import toast from 'react-hot-toast';
import { CustomLinearProgress } from '@/pages/ListContentPage/components/CustomLinearProgress';
import { COLORS, TYPOGRAPHY } from '@/pages/ListContentPage/constants';
import { getColorProgressCourse } from '@/pages/ListContentPage/utils';
import { FloatingActionButton } from '@/pages/ListContentPage/components/ToUpFAB';

export const MainList = () => {
  const [searchParamsFromReactRouter, setSearchParams] = useSearchParams();
  const showProgressModal =
    searchParamsFromReactRouter.get('show_theoretical_modal')?.toLowerCase() ===
    'true';
  //states
  const [activeCourse, setActiveCourse] = useState<number>(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const firstRender = useRef(true);
  const [showUnderConstruction, setShowUnderConstruction] = useState(false);
  const [previousRoute, setPreviousRoute] = useState(0);
  //hooks
  const { t } = useTranslation();
  const metrics = useMetrics();

  // params
  const pensumName = searchParamsFromReactRouter.get(
    QueryParamsStrings.PENSUM_NAME,
  );

  const config = useConfig();
  const {
    routes,
    currentRouteId,
    isLoading: isLoadingRoutes,
    routeFormater,
    getOneRouteInfo,
  } = useUserRoutes();
  const modules = useModules(true);

  const showCerfiticateSection = () => {
    return (
      !config.isMeidei &&
      config.showCertificates &&
      !config.isSimple &&
      activeCourse !== 0
    );
  };

  useEffect(() => {
    if (currentRouteId !== previousRoute) {
      if (currentRouteId && activeCourse === 0) {
        modules.getModules(Number(currentRouteId));
        setActiveCourse(Number(currentRouteId));
      } else {
        modules.getModules(!!activeCourse ? activeCourse : currentRouteId);
      }
      setPreviousRoute(currentRouteId);
    }
  }, [currentRouteId, activeCourse, routes]);

  useEffect(() => {
    const routeInfo = getOneRouteInfo(currentRouteId);
    const canShowUnderConstruction =
      routeInfo.progressPercentage === 100 && routeInfo.finished === false;
    if (canShowUnderConstruction) {
      setShowUnderConstruction(true);
    }
  }, [currentRouteId]);

  useEffect(() => {
    if (Boolean(currentRouteId)) {
      if (
        searchParamsFromReactRouter.get(QueryParamsStrings.SELECTED_ROUTE) ===
        null
      ) {
        setSearchParams({
          ...Object.fromEntries(searchParamsFromReactRouter),
          selected_route: String(currentRouteId),
        });
      }
    }
  }, [routes]);

  useEffect(() => {
    if (searchParamsFromReactRouter.get('party')?.toLowerCase() === 'true') {
      if (!firstRender.current) return;
      setShowConfetti(true);
      toast.success(
        `${t('learningPathScreen.congratulations')}. ${t(
          'contentScreen.upToDate',
        )}`,
        {
          icon: <>🎉</>,
        },
      );
      firstRender.current = false;
    }
  }, [searchParamsFromReactRouter.get('party')]);

  function countHighProgressModules(modules: any) {
    return modules.filter((module: any) => {
      // Si el valor es null, se trata como 0
      const progress =
        module.progress_percentage === null ? 0 : module.progress_percentage;
      return progress >= 90;
    }).length;
  }

  if (currentRouteId === 0) {
    console.log(currentRouteId);
    return <></>;
  }

  return (
    <div style={{ position: 'relative' }}>
      {showConfetti && (
        <>
          <div
            style={{
              width: '100vw',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <ConfettiExplosion />
            </div>
            <div>
              <ConfettiExplosion />
            </div>
          </div>
        </>
      )}
      {showProgressModal && <ContentProgressModal />}
      {showUnderConstruction && (
        <UnderConstruction
          setOpen={setShowUnderConstruction}
          open={showUnderConstruction}
        />
      )}
      <Box
        maxWidth={{
          sm: '100%',
          md: '70%',
        }}
        marginX={'auto'}
      >
        <Heading />
        <Typography
          sx={{
            fontFamily: TYPOGRAPHY.bold,
            fontSize: '1rem',
            marginBottom: '1rem',
            paddingRight: '.3rem',
            textTransform: 'capitalize',
          }}
        >
          {capitalize(pensumName ?? '')}
        </Typography>
        {config.isSimple || modules.data.length === 0 ? (
          <></>
        ) : (
          <Box marginY={'1rem'}>
            <CustomSelect
              label={
                config.isSimple
                  ? 'Mis contenidos'
                  : config.isMeidei
                  ? t('general.courseMeidei')
                  : config.isCustom
                  ? config.isCustom
                  : t('general.course')
              }
              onChange={(id: any) => {
                setActiveCourse(id);
                modules.getModules(id);
                setSearchParams({
                  ...Object.fromEntries(searchParamsFromReactRouter),
                  selected_route: String(id),
                });
                metrics.track({
                  action: 'click-select',
                  extra_data: {
                    button: metrics.MetricsKeys.SELECT_MODULE,
                    path: '/content',
                  },
                });
              }}
              options={routeFormater(routes)}
              currentValue={
                Boolean(activeCourse) ? activeCourse : currentRouteId
              }
            />
          </Box>
        )}

        {modules.isLoading ? (
          ''
        ) : (
          <Box
            component="div"
            marginBottom={'1rem'}
            marginTop={'2rem'}
            width={'100%'}
          >
            <h4
              style={{
                fontSize: '14px',
                marginBottom: '.5rem',
              }}
            >
              {routes.find(
                (id) =>
                  id.route.pk ===
                  Number(
                    searchParamsFromReactRouter.get(
                      QueryParamsStrings.SELECTED_ROUTE,
                    ),
                  ),
              )?.route.name ?? ''}
            </h4>
            <CustomLinearProgress
              progress={
                routes.find(
                  (id) =>
                    id.route.pk ===
                    Number(
                      searchParamsFromReactRouter.get(
                        QueryParamsStrings.SELECTED_ROUTE,
                      ),
                    ),
                )?.progress_percentage ?? 0
              }
              colorTrack={COLORS.GRAY_EXTRA_LIGHT}
              colorBar={getColorProgressCourse(
                Number(
                  routes.find(
                    (id) =>
                      id.route.pk ===
                      Number(
                        searchParamsFromReactRouter.get(
                          QueryParamsStrings.SELECTED_ROUTE,
                        ),
                      ),
                  )?.progress_percentage ?? 0,
                ),
              )}
            />
            <div
              style={{
                fontSize: '12px',
                marginTop: '.8rem',
              }}
            >
              {countHighProgressModules(modules.data)}/{modules.data.length}{' '}
              {t('general.courseModulesCompleted')}
            </div>
          </Box>
        )}
        {isLoadingRoutes ? (
          <></>
        ) : (
          <>
            {routes.length === 0 && (
              <Alert
                severity="info"
                sx={{
                  marginTop: '1rem',
                }}
              >
                {t('contentScreen.noActiveCourse')}
              </Alert>
            )}
          </>
        )}

        {modules.data.length > 0 && (
          <ModuleList
            isLoading={modules.isLoading}
            modules={modules.listModulesFormatter(modules.data)}
          />
        )}
        {showCerfiticateSection() && (
          <AnnounceCertificate
            completedModules={modules.getCompletedModulesCount()}
            totalModules={modules.metadata?.count}
            courseTitle={
              getOneRouteInfo(
                activeCourse === 0 ? currentRouteId : activeCourse,
              )?.name ?? ''
            }
          />
        )}
      </Box>
      {modules.data.length > 0 && <FloatingActionButton />}
    </div>
  );
};

export default MainList;
