import { MainList } from '@/pages/ListContentPage/MainList';
import { RouteWrapper } from '@/pages/ListContentPage/RouteWrapper';

export const ContentPageNewDesign = () => {
  console.log('test');
  return (
    <RouteWrapper>
      <MainList />
    </RouteWrapper>
  );
};

export default ContentPageNewDesign;
