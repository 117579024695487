import { useUser } from '@/components/AuthInterceptorComponent/hooks/useUser';
import { usePendingContent } from '@/hooks';
import { useConfig } from '@/hooks/useConfig';
import { useMetrics } from '@/hooks/useMetrics';
import { usePendingContentRedirect } from '@/hooks/usePendingContentRedirect';
// import { useProfilePage } from '@/pages/v2/profile/hooks/useProfilePage';
import { useTheme } from '@mui/material';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export enum Links {
  HOME = '/',
  MY_COURSES = '/content',
  PENDING = '/content?party=true',
  POINTS = '/points',
}

export const BottomNavbar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const config = useConfig();
  const { data: user } = useUser();
  const { hasPendingContent, pendings, isLoading } = usePendingContent();
  const metrics = useMetrics();
  const { handlePendingContentRedirect } = usePendingContentRedirect();
  const iconStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: '12px',
    position: 'relative',
  };

  // only work with meidei
  const handleMetricsMyAccess = (url: string) => {
    window.location.href = url;
    metrics.track({
      action: 'click',
      extra_data: {
        button: metrics.MetricsKeys.CLICK_MY_ACCESS,
        path: location.pathname,
      },
    });
  };

  return (
    <div
      style={{
        width: '100vw',
        position: 'fixed',
        background: 'white',
        bottom: 0,
        zIndex: 999,
        padding: '1rem',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '300px',
          margin: '0 auto',
        }}
      >
        <div>
          <Link style={{ textDecoration: 'none' }} to={Links.HOME}>
            <div style={iconStyle}>
              {location.pathname === Links.HOME ? (
                <HomeIconFill color={theme.palette.primary.main} />
              ) : (
                <HomeIconOutline color={theme.palette.primary.main} />
              )}
              <span>{t('general.home')}</span>
            </div>
          </Link>
        </div>
        <div>
          <Link style={{ textDecoration: 'none' }} to={Links.MY_COURSES}>
            <div style={iconStyle}>
              {location.pathname === Links.MY_COURSES ? (
                <MyCourseIconFill color={theme.palette.primary.main} />
              ) : (
                <MyCourseIcon color={theme.palette.primary.main} />
              )}
              <span>
                {config.isSimple
                  ? t('homeScreen.myContents')
                  : t(`contentPageNew.myCourses${config.isMeidei}`)}
              </span>
            </div>
          </Link>
        </div>
        <div>
          {Boolean(config.isMeidei) ? (
            <div
              onClick={() =>
                handleMetricsMyAccess(user?.menu_links.my_certs || '')
              }
            >
              <div style={iconStyle}>
                <LinksIcon color={theme.palette.primary.main} />
                <span>Mis accesos</span>
              </div>
            </div>
          ) : (
            <Link style={{ textDecoration: 'none' }} to={Links.POINTS}>
              <div style={iconStyle}>
                {location.pathname === Links.POINTS ? (
                  <PointsIconFill color={theme.palette.primary.main} />
                ) : (
                  <PointsIconOutline color={theme.palette.primary.main} />
                )}
                <span>{t('general.points')}</span>
              </div>
            </Link>
          )}
        </div>
        <div>
          <div onClick={handlePendingContentRedirect} style={iconStyle}>
            {location.pathname === Links.PENDING ? (
              <PendingIconFill color={theme.palette.primary.main} />
            ) : (
              <PendingIconOutline color={theme.palette.primary.main} />
            )}
            {!isLoading && (
              <>{hasPendingContent && <Badge pendings={pendings} />}</>
            )}
            <span>{t('general.pendings')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Badge = ({ pendings }: { pendings: number }) => {
  return (
    <span
      style={{
        background: 'red',
        borderRadius: '50%',
        color: 'white',
        height: '20px',
        width: '20px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '-10px',
        right: '2px',
      }}
    >
      <span
        style={{
          fontSize: '10px',
          fontWeight: 'bold',
        }}
      >
        {pendings}
      </span>
    </span>
  );
};

const HomeIconOutline = ({ color }: { color: string }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z"
          fill={color}
        />
        <path
          d="M17.6009 22.5598H6.40094C4.58094 22.5598 2.92094 21.1598 2.62094 19.3698L1.29094 11.3998C1.07094 10.1598 1.68094 8.56982 2.67094 7.77982L9.60094 2.22982C10.9409 1.14982 13.0509 1.15982 14.4009 2.23982L21.3309 7.77982C22.3109 8.56982 22.9109 10.1598 22.7109 11.3998L21.3809 19.3598C21.0809 21.1298 19.3809 22.5598 17.6009 22.5598ZM11.9909 2.92982C11.4609 2.92982 10.9309 3.08982 10.5409 3.39982L3.61094 8.95982C3.05094 9.40982 2.65094 10.4498 2.77094 11.1598L4.10094 19.1198C4.28094 20.1698 5.33094 21.0598 6.40094 21.0598H17.6009C18.6709 21.0598 19.7209 20.1698 19.9009 19.1098L21.2309 11.1498C21.3409 10.4498 20.9409 9.38982 20.3909 8.94982L13.4609 3.40982C13.0609 3.08982 12.5209 2.92982 11.9909 2.92982Z"
          fill={color}
        />
      </svg>
    </>
  );
};

const HomeIconFill = ({ color }: { color: string }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8593 8.36985L13.9293 2.82985C12.8593 1.96985 11.1293 1.96985 10.0693 2.81985L3.13929 8.36985C2.35929 8.98985 1.85929 10.2998 2.02929 11.2798L3.35929 19.2398C3.59929 20.6598 4.95929 21.8098 6.39929 21.8098H17.5993C19.0293 21.8098 20.3993 20.6498 20.6393 19.2398L21.9693 11.2798C22.1293 10.2998 21.6293 8.98985 20.8593 8.36985ZM11.9993 15.4998C10.6193 15.4998 9.49929 14.3798 9.49929 12.9998C9.49929 11.6198 10.6193 10.4998 11.9993 10.4998C13.3793 10.4998 14.4993 11.6198 14.4993 12.9998C14.4993 14.3798 13.3793 15.4998 11.9993 15.4998Z"
          fill={color}
        />
      </svg>
    </>
  );
};

const MyCourseIcon = ({ color }: { color: string }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 16.7397V4.6697C22 3.4697 21.02 2.5797 19.83 2.6797H19.77C17.67 2.8597 14.48 3.9297 12.7 5.0497L12.53 5.1597C12.24 5.3397 11.76 5.3397 11.47 5.1597L11.22 5.0097C9.44 3.8997 6.26 2.8397 4.16 2.6697C2.97 2.5697 2 3.4697 2 4.6597V16.7397C2 17.6997 2.78 18.5997 3.74 18.7197L4.03 18.7597C6.2 19.0497 9.55 20.1497 11.47 21.1997L11.51 21.2197C11.78 21.3697 12.21 21.3697 12.47 21.2197C14.39 20.1597 17.75 19.0497 19.93 18.7597L20.26 18.7197C21.22 18.5997 22 17.6997 22 16.7397Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 5.49023V20.4902"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.75 8.49023H5.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 11.4902H5.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

const MyCourseIconFill = ({ color }: { color: string }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 4.84969V16.7397C22 17.7097 21.21 18.5997 20.24 18.7197L19.93 18.7597C18.29 18.9797 15.98 19.6597 14.12 20.4397C13.47 20.7097 12.75 20.2197 12.75 19.5097V5.59969C12.75 5.22969 12.96 4.88969 13.29 4.70969C15.12 3.71969 17.89 2.83969 19.77 2.67969H19.83C21.03 2.67969 22 3.64969 22 4.84969Z"
          fill={color}
        />
        <path
          d="M10.7102 4.70969C8.88023 3.71969 6.11023 2.83969 4.23023 2.67969H4.16023C2.96023 2.67969 1.99023 3.64969 1.99023 4.84969V16.7397C1.99023 17.7097 2.78023 18.5997 3.75023 18.7197L4.06023 18.7597C5.70023 18.9797 8.01023 19.6597 9.87023 20.4397C10.5202 20.7097 11.2402 20.2197 11.2402 19.5097V5.59969C11.2402 5.21969 11.0402 4.88969 10.7102 4.70969ZM5.00023 7.73969H7.25023C7.66023 7.73969 8.00023 8.07969 8.00023 8.48969C8.00023 8.90969 7.66023 9.23969 7.25023 9.23969H5.00023C4.59023 9.23969 4.25023 8.90969 4.25023 8.48969C4.25023 8.07969 4.59023 7.73969 5.00023 7.73969ZM8.00023 12.2397H5.00023C4.59023 12.2397 4.25023 11.9097 4.25023 11.4897C4.25023 11.0797 4.59023 10.7397 5.00023 10.7397H8.00023C8.41023 10.7397 8.75023 11.0797 8.75023 11.4897C8.75023 11.9097 8.41023 12.2397 8.00023 12.2397Z"
          fill={color}
        />
      </svg>
    </>
  );
};

const PendingIconFill = ({ color }: { color: string }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.4998 7.49988L18.0098 8.98988L15.0098 5.98988L16.4998 4.49988C16.9198 4.07988 17.4598 3.87988 17.9998 3.87988C18.5398 3.87988 19.0798 4.07988 19.4998 4.49988C20.3298 5.32988 20.3298 6.66988 19.4998 7.49988Z"
          fill={color}
        />
        <path
          d="M17.3095 9.7002L6.49945 20.5002C5.66945 21.3302 4.32945 21.3302 3.49945 20.5002C2.66945 19.6702 2.66945 18.3302 3.49945 17.5002L14.3095 6.7002L17.3095 9.7002Z"
          fill={color}
        />
        <path
          d="M9.95051 3.50002L10.3605 2.11002C10.4005 1.98002 10.3605 1.84002 10.2705 1.74002C10.1805 1.64002 10.0205 1.60002 9.89051 1.64002L8.50051 2.05002L7.11051 1.64002C6.98051 1.60002 6.84051 1.64002 6.74051 1.73002C6.64051 1.83002 6.61051 1.97002 6.65051 2.10002L7.05051 3.50002L6.64051 4.89002C6.60051 5.02002 6.64051 5.16002 6.73051 5.26002C6.83051 5.36002 6.97051 5.39002 7.10051 5.35002L8.50051 4.95002L9.89051 5.36002C9.93051 5.37002 9.96051 5.38002 10.0005 5.38002C10.1005 5.38002 10.1905 5.34002 10.2705 5.27002C10.3705 5.17002 10.4005 5.03002 10.3605 4.90002L9.95051 3.50002Z"
          fill={color}
        />
        <path
          d="M5.95051 9.50002L6.36051 8.11002C6.40051 7.98002 6.36051 7.84002 6.27051 7.74002C6.17051 7.64002 6.03051 7.61002 5.90051 7.65002L4.50051 8.05002L3.1105 7.64002C2.9805 7.60002 2.84051 7.64002 2.74051 7.73002C2.64051 7.83002 2.61051 7.97002 2.65051 8.10002L3.05051 9.50002L2.64051 10.89C2.60051 11.02 2.64051 11.16 2.73051 11.26C2.83051 11.36 2.9705 11.39 3.1005 11.35L4.4905 10.94L5.88051 11.35C5.91051 11.36 5.95051 11.36 5.9905 11.36C6.0905 11.36 6.18051 11.32 6.26051 11.25C6.36051 11.15 6.39051 11.01 6.35051 10.88L5.95051 9.50002Z"
          fill={color}
        />
        <path
          d="M20.9497 14.5L21.3597 13.11C21.3997 12.98 21.3597 12.84 21.2697 12.74C21.1697 12.64 21.0297 12.61 20.8997 12.65L19.5097 13.06L18.1197 12.65C17.9897 12.61 17.8497 12.65 17.7497 12.74C17.6497 12.84 17.6197 12.98 17.6597 13.11L18.0697 14.5L17.6597 15.89C17.6197 16.02 17.6597 16.16 17.7497 16.26C17.8497 16.36 17.9897 16.39 18.1197 16.35L19.5097 15.94L20.8997 16.35C20.9297 16.36 20.9697 16.36 21.0097 16.36C21.1097 16.36 21.1997 16.32 21.2797 16.25C21.3797 16.15 21.4097 16.01 21.3697 15.88L20.9497 14.5Z"
          fill={color}
        />
      </svg>
    </>
  );
};

const PendingIconOutline = ({ color }: { color: string }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.49945 20.4999C4.32945 21.3299 5.66945 21.3299 6.49945 20.4999L19.4995 7.49994C20.3295 6.66994 20.3295 5.32994 19.4995 4.49994C18.6695 3.66994 17.3295 3.66994 16.4995 4.49994L3.49945 17.4999C2.66945 18.3299 2.66945 19.6699 3.49945 20.4999Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0098 8.99023L15.0098 5.99023"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 2.44L10 2L9.56 3.5L10 5L8.5 4.56L7 5L7.44 3.5L7 2L8.5 2.44Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 8.44L6 8L5.56 9.5L6 11L4.5 10.56L3 11L3.44 9.5L3 8L4.5 8.44Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5 13.44L21 13L20.56 14.5L21 16L19.5 15.56L18 16L18.44 14.5L18 13L19.5 13.44Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

const PointsIconFill = ({ color }: { color: string }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22.7499C8 22.7499 4.75 19.8799 4.75 16.3499V12.6499C4.75 12.2399 5.09 11.8999 5.5 11.8999C5.91 11.8999 6.25 12.2399 6.25 12.6499C6.25 15.2699 8.72 17.2499 12 17.2499C15.28 17.2499 17.75 15.2699 17.75 12.6499C17.75 12.2399 18.09 11.8999 18.5 11.8999C18.91 11.8999 19.25 12.2399 19.25 12.6499V16.3499C19.25 19.8799 16 22.7499 12 22.7499ZM6.25 16.4599C6.32 19.1099 8.87 21.2499 12 21.2499C15.13 21.2499 17.68 19.1099 17.75 16.4599C16.45 17.8699 14.39 18.7499 12 18.7499C9.61 18.7499 7.56 17.8699 6.25 16.4599Z"
          fill={color}
        />
        <path
          d="M12 13.75C9.24 13.75 6.75999 12.51 5.54999 10.51C5.02999 9.66 4.75 8.67 4.75 7.65C4.75 5.93 5.52 4.31 6.91 3.09C8.27 1.9 10.08 1.25 12 1.25C13.92 1.25 15.72 1.9 17.09 3.08C18.48 4.31 19.25 5.93 19.25 7.65C19.25 8.67 18.97 9.65 18.45 10.51C17.24 12.51 14.76 13.75 12 13.75ZM12 2.75C10.44 2.75 8.98001 3.27 7.89001 4.23C6.83001 5.15 6.25 6.37 6.25 7.65C6.25 8.4 6.44999 9.1 6.82999 9.73C7.77999 11.29 9.76 12.25 12 12.25C14.24 12.25 16.22 11.28 17.17 9.73C17.56 9.1 17.75 8.4 17.75 7.65C17.75 6.37 17.17 5.15 16.1 4.21C15.01 3.27 13.56 2.75 12 2.75Z"
          fill={color}
        />
        <path
          d="M12 18.75C7.87 18.75 4.75 16.13 4.75 12.65V7.65C4.75 4.12 8 1.25 12 1.25C13.92 1.25 15.72 1.9 17.09 3.08C18.48 4.31 19.25 5.93 19.25 7.65V12.65C19.25 16.13 16.13 18.75 12 18.75ZM12 2.75C8.83 2.75 6.25 4.95 6.25 7.65V8.65C6.25 11.27 8.72 13.25 12 13.25C15.28 13.25 17.75 11.27 17.75 8.65V7.65C17.75 6.37 17.17 5.15 16.1 4.21C15.01 3.27 13.56 2.75 12 2.75Z"
          fill={color}
        />
      </svg>
    </>
  );
};

const PointsIconOutline = ({ color }: { color: string }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22.7504C8 22.7504 4.75 19.8804 4.75 16.3504V12.6504C4.75 12.2404 5.09 11.9004 5.5 11.9004C5.91 11.9004 6.25 12.2404 6.25 12.6504C6.25 15.2704 8.72 17.2504 12 17.2504C15.28 17.2504 17.75 15.2704 17.75 12.6504C17.75 12.2404 18.09 11.9004 18.5 11.9004C18.91 11.9004 19.25 12.2404 19.25 12.6504V16.3504C19.25 19.8804 16 22.7504 12 22.7504ZM6.25 16.4604C6.32 19.1104 8.87 21.2504 12 21.2504C15.13 21.2504 17.68 19.1104 17.75 16.4604C16.45 17.8704 14.39 18.7504 12 18.7504C9.61 18.7504 7.56 17.8704 6.25 16.4604Z"
          fill={color}
        />
        <path
          d="M12 13.75C9.24 13.75 6.75999 12.51 5.54999 10.51C5.02999 9.66 4.75 8.67 4.75 7.65C4.75 5.93 5.52 4.31 6.91 3.09C8.27 1.9 10.08 1.25 12 1.25C13.92 1.25 15.72 1.9 17.09 3.08C18.48 4.31 19.25 5.93 19.25 7.65C19.25 8.67 18.97 9.65 18.45 10.51C17.24 12.51 14.76 13.75 12 13.75ZM12 2.75C10.44 2.75 8.98001 3.27 7.89001 4.23C6.83001 5.15 6.25 6.37 6.25 7.65C6.25 8.4 6.44999 9.1 6.82999 9.73C7.77999 11.29 9.76 12.25 12 12.25C14.24 12.25 16.22 11.28 17.17 9.73C17.56 9.1 17.75 8.4 17.75 7.65C17.75 6.37 17.17 5.15 16.1 4.21C15.01 3.27 13.56 2.75 12 2.75Z"
          fill={color}
        />
        <path
          d="M12 18.75C7.87 18.75 4.75 16.13 4.75 12.65V7.65C4.75 4.12 8 1.25 12 1.25C13.92 1.25 15.72 1.9 17.09 3.08C18.48 4.31 19.25 5.93 19.25 7.65V12.65C19.25 16.13 16.13 18.75 12 18.75ZM12 2.75C8.83 2.75 6.25 4.95 6.25 7.65V12.65C6.25 15.27 8.72 17.25 12 17.25C15.28 17.25 17.75 15.27 17.75 12.65V7.65C17.75 6.37 17.17 5.15 16.1 4.21C15.01 3.27 13.56 2.75 12 2.75Z"
          fill={color}
        />
      </svg>
    </>
  );
};

const LinksIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0598 10.9404C15.3098 13.1904 15.3098 16.8304 13.0598 19.0704C10.8098 21.3104 7.16985 21.3204 4.92985 19.0704C2.68985 16.8204 2.67985 13.1804 4.92985 10.9404"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5909 13.4099C8.25094 11.0699 8.25094 7.26988 10.5909 4.91988C12.9309 2.56988 16.7309 2.57988 19.0809 4.91988C21.4309 7.25988 21.4209 11.0599 19.0809 13.4099"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
